<template>
  <v-snackbar
    v-model="active"
    class="snackbar"
    :color="type"
    location="top right"
    timeout="8000"
  >
    <v-icon
      v-if="icon"
      color="white"
    >
      {{ icon }}
    </v-icon>
    <v-icon
      v-else-if="type === 'warning'"
      color="white"
    >
      mdi-alert-outline
    </v-icon>
    <v-icon
      v-else-if="type === 'error'"
      color="white"
    >
      mdi-alert-circle-outline
    </v-icon>
    <v-icon
      v-else-if="type === 'success'"
      color="white"
    >
      mdi-check-circle-outline
    </v-icon>
    &nbsp;
    <span v-if="title">
      <strong> {{ title }} </strong>
      <br />
    </span>
    {{ text }}
    <template #actions>
      <v-btn
        class="button"
        icon
        :data-cy="'snackbar-' + type"
        @click.stop="active = false"
      >
        <v-icon color="white">mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState, mapWritableState } from 'pinia'
import { useNotificationStore } from '~/store/notification'

export default {
  computed: {
    ...mapState(useNotificationStore, ['type', 'text', 'title', 'icon']),
    ...mapWritableState(useNotificationStore, ['active']),
  },
}
</script>
